<template>
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <div>
          <h4>İlanlar</h4>
        </div>
        <div>
          <button
            style="
              font-size: 14px;
              background-color: rgba(0, 0, 0, 0.08);
              color: #383838;
            "
            class="p-4 rounded-sm"
          >
            Excel Liste
          </button>
        </div>
      </div>
    </template>
    <!-- <div class="d-flex justify-content-start align-items-center my-10">
        Toplam {{ totalItems }} müşteri bulundu.
      </div> -->
    <div
      class="d-flex align-items-center justify-content-between search-list-area"
    >
      <div class="d-flex align-items-center search-input">
        <Input placeholder="Ara.." v-model="search" class="mr-2" />
      </div>
      <b-button
        variant="outline-primary"
        class="d-flex d-md-inline-block mt-0 w-md-auto w-100 mb-6"
        v-b-toggle.sidebar-right
        >Filtrele</b-button
      >
    </div>

    <div class="">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Sistem Kullanıcı Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item._id="{ item }">
          <router-link :to="`find/detail/${item._id.$oid}`">
            <button class="btn btn-sm btn-light">
              <i class="flaticon2-next text-dark p-0"></i>
            </button>
          </router-link>
        </template>
        <template v-slot:item.varlik="{ item }">
          <div class="badges-area">
            <div class="badges">
              <i
                class="badge"
                v-for="(badges, key) in item.badges"
                :key="key"
                :class="[badgesEnum[key]]"
              >
              </i>
            </div>

            <p>{{ item.varlik }}</p>
          </div>
          <div class="ilan-alert" :class="[!item.ilanStatus && 'active']">
            {{ !item.ilanStatus ? "Bu ilan'ın ihalesi iptal edildi." : " " }}
          </div>
        </template>

        <template v-slot:item.step="{ item }">
          <Steps
            :steps="5"
            :activeStep="item.step"
            :stepNames="stepNames"
          />
        </template>
        <template v-slot:item.tapu="{ item }">
          <div class="tapu-text">
            <h6 style="font-size: 14px">{{ item.tapu }}</h6>
            <h6 style="font-size: 12px">{{ item.ilanBilgileri }}</h6>
            <span style="font-size: 11px"
              >tapu: <strong>{{ item.tapuCount }}</strong> | Taşınmaz:
              <strong>{{ item.tasinmazCount }}</strong>
            </span>
          </div>
        </template>
        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex px-4">
            <router-link
              :to="`declaration/detail`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="flaticon-home-1 text-dark p-0"></i
              ></b-button>
            </router-link>
            <router-link
              :to="`/sales-detail`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="flaticon2-photo-camera text-primary p-0"></i
              ></b-button>
            </router-link>

            <router-link
              :to="`declaration/detail`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="flaticon2-sheet text-primary p-0"></i
              ></b-button>
            </router-link>

            <router-link
              :to="`declaration/detail`"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <b-button
                variant="light"
                class="ml-auto"
                :href="href"
                @click="navigate"
                ><i class="flaticon2-reload text-dark p-0"></i
              ></b-button>
            </router-link>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
    <div class="notes-sidebar top-full">
      <b-sidebar id="sidebar-right" right>
        <div
          class="card dveb-gray-card aside-gray-card tab-card"
          style="overflow-y: auto"
        >
          <div class="card-header">FİLTRELE</div>
          <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="Filtre" active>
              <div class="form-group">
                <label for="sahip">Sahibi</label>
                <select name="" id="sahip" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">Şekerbank A.Ş</option>
                </select>
              </div>

              <div class="form-group">
                <label for="durum">Durumu</label>
                <select name="" id="durum" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="il">Şehir</label>
                <select name="" id="il" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
              <div class="form-group">
                <label for="ilce">İlçe</label>
                <select name="" id="ilce" class="form-control">
                  <option value="" selected>Seçiniz</option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>

              <div class="row">
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Temizle </b-button>
                </div>
                <div class="col-md-6 col-12 mt-4">
                  <b-button variant="light" class="w-100"> Uygula </b-button>
                </div>
                <div class="col-12 mt-4">
                  <b-button variant="light" class="w-100">
                    Bu Filtreyi Kaydet
                  </b-button>
                </div>
              </div>
            </b-tab>
            <b-tab title="Hızlı Ara">
              <div
                class="card mt-4 dveb-card-white"
                style="border-radius: 0 !important"
              >
                <div>
                  <h4>Filtre Örnek</h4>
                  <hr />
                  <div class="row ml-auto">
                    <b-button variant="success" class="mr-4"> Uygula </b-button>
                    <b-button
                      variant="danger"
                      class=""
                      @click="openDeleteHandler(key)"
                    >
                      Sil
                    </b-button>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-sidebar>
    </div>
    <DeleteOperation />
  </Card>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
.ilan-alert {
  &.active {
    background-color: rgb(179, 26, 26);
    color: white;
    // padding: 1px;
    border-radius: 10px;
    width: 188px;
    font-size: 11px;
    text-align: center;
    margin-top: 10px;
  }
  &.active:hover {
    background-color: rgb(147, 31, 31);
    transition: 0.2s;
    cursor: default;
  }
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ADVERTS } from "@/core/services/store/adverts/adverts.module";
import { mapGetters } from "vuex";
export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "İlanlar", route: "/adverts/find" },
    ]);
  },
  name: "customerList",
  components: {
    Steps: () => import("@/components/global/StepBar"),
  },
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames: ["H:RY", "O:GB", "Y:RY", "İ:RY", "D:GB"],
      header: [
        { text: "", value: "_id", hideMobile:true, size: "50px" },
        { text: "Varlık", value: "property_id" },
        { text: "İlan Bilgileri", value: "tapu" },
        { text: "Durum", value: "step" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList: [],
    };
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `&Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }
      //**************************************************** */
      if (this.$route.params.id){
        query += '&property_id='+ this.$route.params.id
      }
      //**************************************************** */
      const payload = {myUser : this.myUser , query:query}
      this.$store.dispatch(ADVERTS,payload).then((Response)=>{
        this.items = Response
        this.totalItems = this.items.length;
        this.pagination = {
          current_page: this.$emit("changePage").pagination.page ? this.$emit("changePage").pagination.page : 1,
          total_pages: Math.ceil(this.items.length / 10),
          total_items: this.items.length,
        };
        this.loading = false;
      })
      
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show("modal-deleteOperation");
      let index = this.notes.indexOf(key);
      this.notes.splice(index, 1);
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed:{
    ...mapGetters(["myUser"])
  }
};
</script>
